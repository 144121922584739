import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import AboutDoc from "./AboutDoc";
import 'react-toastify/dist/ReactToastify.css';
import { Link, useNavigate } from "react-router-dom";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';
import { API_BASE_URL } from "../../environment.js/environment";
const Hero = () => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [resetElements, setResetElements] = useState(false);
  const [errors, setErrors] = useState({});
  const [disable, setDisable] = useState(false);
  const [showForm, setFormShow] = useState(false);
  const [passwordValidation, setPasswordValidation] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    number: false,
    specialChar: false,
  });
  
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setDisable(true);
    const validationErrors = validateForm();
    setErrors(validationErrors)
    debugger
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      setDisable(false);
      return;
    }
    if (Object.keys(validationErrors).length === 0) {
      const cardElement = elements.getElement(CardNumberElement);

      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
      });
      if (error) {
        setDisable(false);
        console.log('[error]', error);
        return
      } else {
        console.log('[PaymentMethod]', paymentMethod);
        setFormData((prevFormData) => ({
          ...prevFormData,
          'token': paymentMethod.id,
        }));
        debugger
        // Here you can call your backend endpoint to process the payment
      }
      console.log(formData);
      var formDataCopy = { ...formData, token: paymentMethod.id }
      setDisable(true);
      const response = await fetch(API_BASE_URL+"/api/Doctors/CreateDoctor", {
        method: 'POST',
        body: JSON.stringify(formDataCopy),
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok) {
        if (response.status ==400) {
          var res =await response.json();
          toast.error(res.message);
          setDisable(false);
          return;
        }
        console.log(formData);
        toast.error('Error While Submitting the Application. Recheck your Information or Contact Custom Support!');
        setDisable(false);
      } else {
        // Handle success
        setResetElements(true);
        setTimeout(() => setResetElements(false), 0);
        toast.success('Successfully Registered!')
      
        setFormData({
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
          refNumber: "",
          country: "",
          password: "",
          cardHolderName: "",
          cardNumber: "",
          exp_Date: "",
          cVC: "",
          paymentFrom: "",
        });
        window.location.href='/login'
      }
      setTimeout(() => {
        setDisable(false);
      }, 3000);
    }
    else {
      setErrors(validationErrors);
      setDisable(false)
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    refNumber: "",
    country: "",
    password: "",
    password:'',
    cardHolderName: "",
    cardNumber: "",
    exp_Date: "",
    cVC: "",
    paymentFrom: "",
    token: '',
  });
  const handleCardChange = (e, field) => {
    const valueObjct = e.complete;
    if (valueObjct) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [field]: true,
      }));
    }
    else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [field]: false,
      }));
    }
  }

  const ELEMENT_OPTIONS = {
    style: {
      base: {
        color: "white",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        border: "white",
        border: "1px solid white", // Adds a solid white border
        "::placeholder": {
          color: "#aab7c4"
        }
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a"
      }
    }
  };
  const handlePasswordChange = (e) => {
    debugger
    const { value } = e.target;
    setFormData({ ...formData, password: value });
    setPasswordValidation({
      length: value.length >= 8,
      uppercase: /[A-Z]/.test(value),
      lowercase: /[a-z]/.test(value),
      number: /[0-9]/.test(value),
      specialChar: /[!@#$%^&*(),.?":{}|<>]/.test(value),
    });
  
    // Additional validation logic if needed
    if (value.trim() === "") {
      errors.password = "Password is required";
    }
    else{
      errors.password = null;
    }
    setErrors(errors);
  };
  const validateForm = () => {
    let validationErrors = {};

    // Validate first name
    if (formData.firstName.trim() === "") {
      validationErrors.firstName = "First name is required";
    }

    // Validate last name
    if (formData.lastName.trim() === "") {
      validationErrors.lastName = "Last name is required";
    }

    // Validate email
    if (formData.email.trim() === "") {
      validationErrors.email = "Email is required";
    } else if (!isValidEmail(formData.email)) {
      validationErrors.email = "Invalid email format";
    }

    // Validate phone
    if (formData.phone.trim() === "") {
      validationErrors.phone = "Phone number is required";
    }
    if (formData.country.trim() === "") {
      validationErrors.country = "Country is required";
    }
    if (formData.password.trim() === "") {
      validationErrors.password = "Password is required";
    } else if (formData.password.length < 8) {
      validationErrors.password = "Password must be at least 8 characters long";
    } else if (!/[A-Z]/.test(formData.password)) {
      validationErrors.password = "Password must contain at least one uppercase letter";
    } else if (!/[a-z]/.test(formData.password)) {
      validationErrors.password = "Password must contain at least one lowercase letter";
    } else if (!/[0-9]/.test(formData.password)) {
      validationErrors.password = "Password must contain at least one number";
    } else if (!/[!@#$%^&*(),.?":{}|<>]/.test(formData.password)) {
      validationErrors.password = "Password must contain at least one special character";
    }
    // Validate card number
    if (formData.cardNumber === "" || !formData.cardNumber) {
      validationErrors.cardNumber = "Card number is required";
    }

    // Validate expiration date
    if (formData.exp_Date === "" || !formData.exp_Date) {
      validationErrors.exp_Date = "Expiration date is required";
    }

    // Validate CVC
    if (formData.cVC === "" || !formData.cVC) {
      validationErrors.cVC = "CVC is required";
    }

    // Add more validation rules for other fields...

    return validationErrors;
  };
  const isValidEmail = (email) => {
    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const stripe = useStripe();
  const elements = useElements();
  return (
    <>
      <section className={showForm ? "Hero" : "Hero HeroHeightVh"}>
      <ToastContainer />
        <div className="Background">
          <div className="Rectangle-One" />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <div className="Hero-Content wm-md-100 gap-24">
                <div className="Content">
                  <div>
                    <h4>
                      Global Doctors Association: Uniting Medical Excellence
                      Worldwide
                    </h4>
                  </div>
                  <div>
                    <p>
                    Our mission is to unite top medical minds, driving excellence and innovation in global patient care. Join us in improving health through expertise and dedication.
                    </p>
                  </div>
                </div>

                {/* <div className="bottom-content">
                  <div className="scrolldown">
                    <img src="/images/scrolldown.png" />
                    Scroll Down
                  </div>
                  <div>
                    <ul className="list-inline">
                      <li>
                        <Link to="">
                          <img src="/images/facebook.png" />
                        </Link>
                      </li>
                      <li>
                        <Link to="">
                          <img src="/images/twit.png" />
                        </Link>
                      </li>
                      <li>
                        <Link to="">
                          <img src="/images/tele.png" />
                        </Link>
                      </li>
                      <li>
                        <Link to="">
                          <img src="/images/in.png" />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div> */}
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="calculator">
                <div className="content" style={{position:'relative'}}>
                  <h2>Membership Registration</h2>
                  <div className="launch-price">
                    <h4>For nurses, physicians, and personal trainers.</h4>
                  </div>
                  <span style={{position:'absolute', right:"20px", top:'30px', cursor:'pointer'}} onClick={()=>{setFormShow(!showForm)}}>
                    {!showForm && <img alt="img" src="./images/dropdown-48.png" />}
                    {showForm && <img alt="img" src="./images/dropdown-48-down.png" />}
                    
                  </span>
                  {
                    showForm && <form className="calculateform">
                    <div className="form-group">
                      <div className="form">
                        <div>
                          <input
                            type="text"
                            className={`form-control ${errors.firstName ? "is-invalid" : ""
                            }`}
                            placeholder="First Name"
                             id="firstName"
                            name="firstName"
                            value={formData.firstName}
                            onChange={handleChange}
                          />
                        </div>
                        <div>
                          <input
                            type="text"
                            placeholder="Last Name"
                            className={`form-control ${errors.lastName ? "is-invalid" : ""
                            }`}
                            id="lastName"
                            name="lastName"
                            value={formData.lastName}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="form">
                        <div>
                          <input
                            type="text"
                            placeholder="Email"
                            className={`form-control ${errors.email ? "is-invalid" : ""
                            }`}
                            id="email"
                            name="email"
                            value={formData.email}
                            autoComplete="new-email" 
                            onChange={handleChange}
                          />
                            {errors.email && errors.email.includes('Invalid') && (
                            <div className="invalid-feedback">
                              {errors.email}
                            </div>
                          )}
                        </div>
                        <div>
                          <input
                            type="text"
                            placeholder="Phone"
                            className={`form-control ${errors.phone ? "is-invalid" : ""
                            }`}
                            id="phone"
                            name="phone"
                            value={formData.phone}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="form">
                        <div className="select">
                          <select
                           className={`form-control ${errors.country ? "is-invalid" : ""
                           }`}
                           id="country"
                           name="country"
                           value={formData.country}
                           onChange={handleChange}
                           >
                            <option value="">--- Select Country ---</option>
                            <option value="AF">Afghanistan</option>
                            <option value="AL">Albania</option>
                            <option value="DZ">Algeria</option>
                            <option value="AS">American Samoa</option>
                            <option value="AD">Andorra</option>
                            <option value="AO">Angola</option>
                            <option value="AI">Anguilla</option>
                            <option value="AQ">Antarctica</option>
                            <option value="AG">Antigua and Barbuda</option>
                            <option value="AR">Argentina</option>
                            <option value="AM">Armenia</option>
                            <option value="AW">Aruba</option>
                            <option value="AU">Australia</option>
                            <option value="AT">Austria</option>
                            <option value="AZ">Azerbaijan</option>
                            <option value="BS">Bahamas</option>
                            <option value="BH">Bahrain</option>
                            <option value="BD">Bangladesh</option>
                            <option value="BB">Barbados</option>
                            <option value="BY">Belarus</option>
                            <option value="BE">Belgium</option>
                            <option value="BZ">Belize</option>
                            <option value="BJ">Benin</option>
                            <option value="BM">Bermuda</option>
                            <option value="BT">Bhutan</option>
                            <option value="BO">Bolivia</option>
                            <option value="BA">Bosnia and Herzegovina</option>
                            <option value="BW">Botswana</option>
                            <option value="BR">Brazil</option>
                            <option value="IO">British Indian Ocean Territory</option>
                            <option value="BN">Brunei Darussalam</option>
                            <option value="BG">Bulgaria</option>
                            <option value="BF">Burkina Faso</option>
                            <option value="BI">Burundi</option>
                            <option value="CV">Cabo Verde</option>
                            <option value="KH">Cambodia</option>
                            <option value="CM">Cameroon</option>
                            <option value="CA">Canada</option>
                            <option value="KY">Cayman Islands</option>
                            <option value="CF">Central African Republic</option>
                            <option value="TD">Chad</option>
                            <option value="CL">Chile</option>
                            <option value="CN">China</option>
                            <option value="CX">Christmas Island</option>
                            <option value="CC">Cocos (Keeling) Islands</option>
                            <option value="CO">Colombia</option>
                            <option value="KM">Comoros</option>
                            <option value="CG">Congo</option>
                            <option value="CD">Congo, Democratic Republic of the</option>
                            <option value="CK">Cook Islands</option>
                            <option value="CR">Costa Rica</option>
                            <option value="HR">Croatia</option>
                            <option value="CU">Cuba</option>
                            <option value="CW">Curaçao</option>
                            <option value="CY">Cyprus</option>
                            <option value="CZ">Czech Republic</option>
                            <option value="DK">Denmark</option>
                            <option value="DJ">Djibouti</option>
                            <option value="DM">Dominica</option>
                            <option value="DO">Dominican Republic</option>
                            <option value="EC">Ecuador</option>
                            <option value="EG">Egypt</option>
                            <option value="SV">El Salvador</option>
                            <option value="GQ">Equatorial Guinea</option>
                            <option value="ER">Eritrea</option>
                            <option value="EE">Estonia</option>
                            <option value="SZ">Eswatini</option>
                            <option value="ET">Ethiopia</option>
                            <option value="FK">Falkland Islands (Malvinas)</option>
                            <option value="FO">Faroe Islands</option>
                            <option value="FJ">Fiji</option>
                            <option value="FI">Finland</option>
                            <option value="FR">France</option>
                            <option value="GF">French Guiana</option>
                            <option value="PF">French Polynesia</option>
                            <option value="TF">French Southern Territories</option>
                            <option value="GA">Gabon</option>
                            <option value="GM">Gambia</option>
                            <option value="GE">Georgia</option>
                            <option value="DE">Germany</option>
                            <option value="GH">Ghana</option>
                            <option value="GI">Gibraltar</option>
                            <option value="GR">Greece</option>
                            <option value="GL">Greenland</option>
                            <option value="GD">Grenada</option>
                            <option value="GP">Guadeloupe</option>
                            <option value="GU">Guam</option>
                            <option value="GT">Guatemala</option>
                            <option value="GG">Guernsey</option>
                            <option value="GN">Guinea</option>
                            <option value="GW">Guinea-Bissau</option>
                            <option value="GY">Guyana</option>
                            <option value="HT">Haiti</option>
                            <option value="VA">Holy See</option>
                            <option value="HN">Honduras</option>
                            <option value="HK">Hong Kong</option>
                            <option value="HU">Hungary</option>
                            <option value="IS">Iceland</option>
                            <option value="IN">India</option>
                            <option value="ID">Indonesia</option>
                            <option value="IR">Iran</option>
                            <option value="IQ">Iraq</option>
                            <option value="IE">Ireland</option>
                            <option value="IM">Isle of Man</option>
                            <option value="IL">Israel</option>
                            <option value="IT">Italy</option>
                            <option value="JM">Jamaica</option>
                            <option value="JP">Japan</option>
                            <option value="JE">Jersey</option>
                            <option value="JO">Jordan</option>
                            <option value="KZ">Kazakhstan</option>
                            <option value="KE">Kenya</option>
                            <option value="KI">Kiribati</option>
                            <option value="KP">Korea (North)</option>
                            <option value="KR">Korea (South)</option>
                            <option value="KW">Kuwait</option>
                            <option value="KG">Kyrgyzstan</option>
                            <option value="LA">Lao People's Democratic Republic</option>
                            <option value="LV">Latvia</option>
                            <option value="LB">Lebanon</option>
                            <option value="LS">Lesotho</option>
                            <option value="LR">Liberia</option>
                            <option value="LY">Libya</option>
                            <option value="LI">Liechtenstein</option>
                            <option value="LT">Lithuania</option>
                            <option value="LU">Luxembourg</option>
                            <option value="MO">Macao</option>
                            <option value="MG">Madagascar</option>
                            <option value="MW">Malawi</option>
                            <option value="MY">Malaysia</option>
                            <option value="MV">Maldives</option>
                            <option value="ML">Mali</option>
                            <option value="MT">Malta</option>
                            <option value="MH">Marshall Islands</option>
                            <option value="MQ">Martinique</option>
                            <option value="MR">Mauritania</option>
                            <option value="MU">Mauritius</option>
                            <option value="YT">Mayotte</option>
                            <option value="MX">Mexico</option>
                            <option value="FM">Micronesia</option>
                            <option value="MD">Moldova</option>
                            <option value="MC">Monaco</option>
                            <option value="MN">Mongolia</option>
                            <option value="ME">Montenegro</option>
                            <option value="MS">Montserrat</option>
                            <option value="MA">Morocco</option>
                            <option value="MZ">Mozambique</option>
                            <option value="MM">Myanmar</option>
                            <option value="NA">Namibia</option>
                            <option value="NR">Nauru</option>
                            <option value="NP">Nepal</option>
                            <option value="NL">Netherlands</option>
                            <option value="NC">New Caledonia</option>
                            <option value="NZ">New Zealand</option>
                            <option value="NI">Nicaragua</option>
                            <option value="NE">Niger</option>
                            <option value="NG">Nigeria</option>
                            <option value="NU">Niue</option>
                            <option value="NF">Norfolk Island</option>
                            <option value="MK">North Macedonia</option>
                            <option value="MP">Northern Mariana Islands</option>
                            <option value="NO">Norway</option>
                            <option value="OM">Oman</option>
                            <option value="PK">Pakistan</option>
                            <option value="PW">Palau</option>
                            <option value="PS">Palestine, State of</option>
                            <option value="PA">Panama</option>
                            <option value="PG">Papua New Guinea</option>
                            <option value="PY">Paraguay</option>
                            <option value="PE">Peru</option>
                            <option value="PH">Philippines</option>
                            <option value="PN">Pitcairn</option>
                            <option value="PL">Poland</option>
                            <option value="PT">Portugal</option>
                            <option value="PR">Puerto Rico</option>
                            <option value="QA">Qatar</option>
                            <option value="RE">Réunion</option>
                            <option value="RO">Romania</option>
                            <option value="RU">Russian Federation</option>
                            <option value="RW">Rwanda</option>
                            <option value="BL">Saint Barthélemy</option>
                            <option value="SH">Saint Helena, Ascension and Tristan da Cunha</option>
                            <option value="KN">Saint Kitts and Nevis</option>
                            <option value="LC">Saint Lucia</option>
                            <option value="MF">Saint Martin (French part)</option>
                            <option value="PM">Saint Pierre and Miquelon</option>
                            <option value="VC">Saint Vincent and the Grenadines</option>
                            <option value="WS">Samoa</option>
                            <option value="SM">San Marino</option>
                            <option value="ST">Sao Tome and Principe</option>
                            <option value="SA">Saudi Arabia</option>
                            <option value="SN">Senegal</option>
                            <option value="RS">Serbia</option>
                            <option value="SC">Seychelles</option>
                            <option value="SL">Sierra Leone</option>
                            <option value="SG">Singapore</option>
                            <option value="SX">Sint Maarten (Dutch part)</option>
                            <option value="SK">Slovakia</option>
                            <option value="SI">Slovenia</option>
                            <option value="SB">Solomon Islands</option>
                            <option value="SO">Somalia</option>
                            <option value="ZA">South Africa</option>
                            <option value="GS">South Georgia and the South Sandwich Islands</option>
                            <option value="SS">South Sudan</option>
                            <option value="ES">Spain</option>
                            <option value="LK">Sri Lanka</option>
                            <option value="SD">Sudan</option>
                            <option value="SR">Suriname</option>
                            <option value="SE">Sweden</option>
                            <option value="CH">Switzerland</option>
                            <option value="SY">Syrian Arab Republic</option>
                            <option value="TW">Taiwan</option>
                            <option value="TJ">Tajikistan</option>
                            <option value="TZ">Tanzania</option>
                            <option value="TH">Thailand</option>
                            <option value="TL">Timor-Leste</option>
                            <option value="TG">Togo</option>
                            <option value="TK">Tokelau</option>
                            <option value="TO">Tonga</option>
                            <option value="TT">Trinidad and Tobago</option>
                            <option value="TN">Tunisia</option>
                            <option value="TR">Turkey</option>
                            <option value="TM">Turkmenistan</option>
                            <option value="TC">Turks and Caicos Islands</option>
                            <option value="TV">Tuvalu</option>
                            <option value="UG">Uganda</option>
                            <option value="UA">Ukraine</option>
                            <option value="AE">United Arab Emirates</option>
                            <option value="GB">United Kingdom</option>
                            <option value="US">United States</option>
                            <option value="UY">Uruguay</option>
                            <option value="UZ">Uzbekistan</option>
                            <option value="VU">Vanuatu</option>
                            <option value="VE">Venezuela</option>
                            <option value="VN">Viet Nam</option>
                            <option value="VG">Virgin Islands (British)</option>
                            <option value="VI">Virgin Islands (U.S.)</option>
                            <option value="WF">Wallis and Futuna</option>
                            <option value="EH">Western Sahara</option>
                            <option value="YE">Yemen</option>
                            <option value="ZM">Zambia</option>
                            <option value="ZW">Zimbabwe</option>
                          </select>
                        </div>
                        <div>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Ref Number"
                          />
                        </div>
                      </div>
                      <div className="form">
                        <div className="password-wrapper" style={{ position: 'relative' }}>
                          <input
                           className={`form-control ${errors.password ? "is-invalid" : ""
                           }`}
                            id="password"
                            name="password"
                            value={formData.password}
                            autoComplete="new-password" 
                            onChange={handlePasswordChange}
                            type={passwordVisible ? "text" : "password"}
                            required
                            pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W).{8,}"
                            title="Password must be at least 8 characters long, include an uppercase letter, a lowercase letter, a number, and a special character."
                          />
                            {errors.password  && (
                            <div className="invalid-feedback">
                              {errors.password}
                            </div>)}
                          
                          <span
                            onClick={togglePasswordVisibility}
                            style={{
                              position: 'absolute',
                              right: '10px',
                              top: errors.password ? '30%' :'50%',
                              transform: 'translateY(-50%)',
                              cursor: 'pointer'
                            }}
                          >
                            {passwordVisible ?  (
                              <svg
                                fill="white"
                                width="25"
                                height="25"
                                viewBox="0 0 36 36"
                                version="1.1"
                                preserveAspectRatio="xMidYMid meet"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                              >
                                <title>eye-hide-solid</title>
                                <path
                                  d="M18.37,11.17A6.79,6.79,0,0,0,16,11.6l8.8,8.8A6.78,6.78,0,0,0,25.23,18,6.86,6.86,0,0,0,18.37,11.17Z"
                                  className="clr-i-solid clr-i-solid-path-1"
                                ></path>
                                <path
                                  d="M34.29,17.53c-3.37-6.23-9.28-10-15.82-10a16.82,16.82,0,0,0-5.24.85L14.84,10a14.78,14.78,0,0,1,3.63-.47c5.63,0,10.75,3.14,13.8,8.43a17.75,17.75,0,0,1-4.37,5.1l1.42,1.42a19.93,19.93,0,0,0,5-6l.26-.48Z"
                                  className="clr-i-solid clr-i-solid-path-2"
                                ></path>
                                <path
                                  d="M4.87,5.78l4.46,4.46a19.52,19.52,0,0,0-6.69,7.29L2.38,18l.26.48c3.37,6.23,9.28,10,15.82,10a16.93,16.93,0,0,0,7.37-1.69l5,5,1.75-1.5-26-26Zm8.3,8.3a6.85,6.85,0,0,0,9.55,9.55l1.6,1.6a14.91,14.91,0,0,1-5.86,1.2c-5.63,0-10.75-3.14-13.8-8.43a17.29,17.29,0,0,1,6.12-6.3Z"
                                  className="clr-i-solid clr-i-solid-path-3"
                                ></path>
                                <rect x="0" y="0" width="36" height="36" fillOpacity="0" />
                              </svg>
                            ): (
                              <svg
                                fill="white"
                                width="25"
                                height="25"
                                viewBox="0 0 36 36"
                                version="1.1"
                                preserveAspectRatio="xMidYMid meet"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                              >
                                <title>eye-solid</title>
                                <path
                                  d="M33.62,17.53c-3.37-6.23-9.28-10-15.82-10S5.34,11.3,2,17.53L1.72,18l.26.48c3.37,6.23,9.28,10,15.82,10s12.46-3.72,15.82-10l.26-.48ZM17.8,26.43C12.17,26.43,7,23.29,4,18c3-5.29,8.17-8.43,13.8-8.43S28.54,12.72,31.59,18C28.54,23.29,23.42,26.43,17.8,26.43Z"
                                  className="clr-i-solid clr-i-solid-path-1"
                                ></path>
                                <circle
                                  cx="18.09"
                                  cy="18.03"
                                  r="6.86"
                                  className="clr-i-solid clr-i-solid-path-2"
                                ></circle>
                                <rect x="0" y="0" width="36" height="36" fillOpacity="0" />
                              </svg>
                            )}

                          </span>
                         
                        </div>
                      </div>
                      { formData.password &&( <ul className="password-requirements">
                              <li style={{ color: passwordValidation.length ? "#17e517" : "red" }}>
                                At least 8 characters
                              </li>
                              <li style={{ color: passwordValidation.uppercase ? "#17e517" : "red" }}>
                                At least one uppercase letter
                              </li>
                              <li style={{ color: passwordValidation.lowercase ? "#17e517" : "red" }}>
                                At least one lowercase letter
                              </li>
                              <li style={{ color: passwordValidation.number ? "#17e517" : "red" }}>
                                At least one number
                              </li>
                              <li style={{ color: passwordValidation.specialChar ? "#17e517" : "red" }}>
                                At least one special character
                              </li>
                            </ul>)}
                      <div className="head d-flex align-items-center justify-content-between">
                        <div>
                          <span>
                            Credit Card Payment <b>Secured by Stripe</b>
                          </span>
                        </div>
                        <div>
                          <span>
                            <img
                              style={{ maxWidth: "40px" }}
                              src="./images/visa.jpg"
                              className="me-2"
                            />
                          </span>
                          <span>
                            <img
                              style={{ maxWidth: "40px" }}
                              src="./images/master.png"
                            />
                          </span>
                        </div>
                      </div>
                      <div className="row pt-0">
                        <div className="col-md-12">
                          <div class="form-heading">
                            <label>Expiration Date</label>
                          </div>

                          <div className="form">
                            <div  style={{
                          border: errors.cardNumber ? '1px solid red' : '1px solid rgba(217, 217, 217, 0.2)',
                          borderRadius: '4px', padding: '10px', marginTop: '8px', marginBottom: '10px'
                        }}>
                            <CardNumberElement
                           key={resetElements ? 1 : 0} options={ELEMENT_OPTIONS}
                           onChange={(e) => handleCardChange(e, 'cardNumber')}
                          />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row pt-0">
                        <div className="col-md-6">
                          <div class="form-heading mt-0">
                            <label>Expiration Date</label>
                          </div>

                          <div className="form">
                          <div style={{
                              border: errors.exp_Date? '1px solid red' : '1px solid rgba(217, 217, 217, 0.2)',
                              borderRadius: '4px', padding: '10px', marginTop: '8px', marginBottom: '10px'
                            }}>
                            <CardExpiryElement
                                                              key={resetElements ? 1 : 0} options={ELEMENT_OPTIONS}
                                                              onChange={(e) => handleCardChange(e, 'exp_Date')}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div class="form-heading mt-0">
                            <label>CVC</label>
                          </div>

                          <div className="form">
                            <div style={{
                              border: errors.cVC? '1px solid red' : '1px solid rgba(217, 217, 217, 0.2)',
                              borderRadius: '4px', padding: '10px', marginTop: '8px', marginBottom: '10px'
                            }}>
                            <CardCvcElement
                                key={resetElements ? 1 : 0} options={ELEMENT_OPTIONS}
                                onChange={(e) => handleCardChange(e, 'cVC')} 
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12">

                          <div className="form">
                            <div>
                              <input
                                type="text"
                                className="form-control"
                                disabled
                                placeholder="25$ monthly membership"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="calculate-btn">
                      <button disabled={disable} type="button" className="btn form-control"  onClick={(e) => { handleSubmit(e) }}>
                        Submit
                      </button>
                    </div>
                  </form>
                  }
                  
                </div>
              </div>
            </div>
          </div>
        </div>
        {!showForm &&   <AboutDoc formShow={false} />}
      </section>
      {showForm &&   <AboutDoc formShow={true}   />}

    </>
  );
};

export default Hero;
